@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Cedarville+Cursive&family=Chakra+Petch:wght@300&family=Cherry+Bomb+One&family=Markazi+Text:wght@400;600&family=Noto+Serif+Vithkuqi&family=Poppins:ital,wght@0,100;1,300;1,500;1,900&family=Protest+Revolution&family=Marcellus&family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:"Hind Siliguri", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Hind Siliguri", sans-serif;
}
