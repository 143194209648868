button {
  background-color: #e1ab00;
}
.ant-spin-dot-item{
  background-color: #2193d1 !important;
}
.interior {
  background: url("./Assets/interior.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.tech {
  background: url("./Assets/tech.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  border-radius: 5px;
}
* {
  scrollbar-width: none;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(207, 205, 205);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-dropdown-menu {
  border-radius: 0px !important;
  width: 150px !important;
}
.categoryDropDown .ant-dropdown-menu {
  border-radius: 0px !important;
  width: 250px !important;
  /* margin-top: 10px !important; */
}
.signupForm .ant-col.ant-col-24.ant-form-item-label {
  padding: 0px !important;
}
.image-Modal .ant-modal-content {
  background: transparent;
  box-shadow: none;
}

.searchDrawer .ant-drawer-wrapper-body .ant-drawer-body {
  padding: 0;
}

.searchbar .ant-select-selector {
  border-radius: 0;
  border: none;
}
.ant-form-item-label {
  padding: 0 !important;
}

/* sidebar drawer css */
.sidebar-drawer {
  background-color: #2193d1 !important;
}
.sidebar-drawer
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-header-title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* SIDE BAR ACCORDIAN */
.sidebar-accordian .ant-collapse-item .ant-collapse-header {
  color: white;
}
.sidebar-accordian
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  color: white;
}
.sidebar-accordian .ant-collapse-item {
  border-bottom: 0;
}
.category-tree .ant-tree-list {
  position: inherit !important;
  background-color: #2193d1;
  color: white;
}
.pagination .ant-pagination-prev {
  background-color: black;
  border-radius: 0;
}
.pagination .ant-pagination-prev button {
  color: white !important;
}
.pagination .ant-pagination-next {
  background-color: black;
  border-radius: 0;
}
.pagination .ant-pagination-next button {
  color: white !important;
}
.pagination .ant-pagination-item-active {
  background: #2193d1 !important;
  border-radius: 0;
  color: white;
  border: none !important;
  text-decoration: none;
}
.pagination .ant-pagination-item-active a {
  color: white !important;
}
.pagination .ant-pagination-item {
  background-color: white;
  border-radius: 0;
  box-shadow: 2px 2px 5px gray;
}

/* .p-megamenu-root-list {
  gap: 13px !important;
  text-transform: capitalize;
}
.p-megamenu-root-list .p-menuitem-active .p-megamenu-panel {
  background-color: #f8f8f8;
  padding: 5px;
  border-top:3px solid #2193d1;
  margin-top: 2px;
  width: 15vw;
  min-width: 25vw;
  border-radius: 5px;
}
.p-megamenu-panel
  .p-megamenu-grid
  .p-megamenu-col-12
  .p-submenu-list
  .p-megamenu-submenu-header
  p {
  color: #252525 !important;
  font-weight: 600 !important;
  font-size: 14px;
}
.p-megamenu-panel
  .p-megamenu-grid
  .p-megamenu-col-12
  .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-menuitem-text 
  p {
  color: rgb(161, 154, 154) !important;
  font-weight: 500 !important;
  padding-top: 4px;
  line-height: 20px;
  font-size: 13px;

}
.p-megamenu-submenu-header .p-submenu-header p {
  color: black !important;
  font-weight: bold !important;
} */
.sectionHeading::before{
  position: absolute;
  content: "";
  width: 100px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: url("./Assets/leaves.webp");
  top: -13px;
  left: -105px;
  transform:rotate(180deg);
}
.sectionHeading::after{
  position: absolute;
  content: "";
  width: 100px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: url("./Assets/leaves.webp");
  top: 10px;
  right: -100px;
}
.underlineText::before{
  position: absolute;
  content: "";
  background-color: rgb(179, 179, 179);
  height: 1px;
  width: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s all ease-in-out;
}
.underlineText:hover:before{
  width: 100%;
  left: 0;
}
.individualCategory .ant-tree-list .ant-tree-list-holder .ant-tree-list-holder-inner .ant-tree-treenode .ant-tree-node-content-wrapper{
  width: 100% !important;
}
.individualCategory .ant-tree-list .ant-tree-list-holder .ant-tree-list-holder-inner .ant-tree-treenode {
  width: 100% !important;
}

.skeleton {
  width:  100% !important;
}
.ant-modal-content{
  border-radius: 0px !important;
}